
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MigrationOptions from "@/components/migration/MigrationOptions.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "broker-policy-migration-creation",
  components: { MigrationOptions },
  data() {
    return {
      profileCreation: variables.BROKER_CLIENT_ROUTE,
      redirect: "broker-policy-migration-creation",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Create Migration", ["Policy Migration"]);
    });
  },
  created() {
    //Set page title
    document.title = "Broker: Create Migration | " + process.env.VUE_APP_TITLE;
  },
});
